function bindScrollFadeItems() {
    var lastKnownScrollPosition = 0;
    var ticking = false;
    var elementsToFade = Array.from(window.document.getElementsByClassName('scroll-fade'));
    if (elementsToFade.length) {
        window.document.addEventListener('scroll', function (e) {
            lastKnownScrollPosition = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(function () {
                    if (lastKnownScrollPosition > 0) {
                        elementsToFade.forEach(function (item) {
                            var element = item;
                            var classesToAddString = element.dataset.fadeTo;
                            var classesToAdd = classesToAddString ? classesToAddString.split(' ') : [];
                            classesToAdd.forEach(function (style) {
                                element.classList.add(style);
                            });
                        });
                    }
                    else {
                        elementsToFade.forEach(function (item) {
                            var element = item;
                            var classesToRemoveString = element.dataset.fadeTo;
                            var classesToRemove = classesToRemoveString ? classesToRemoveString.split(' ') : [];
                            classesToRemove.forEach(function (style) {
                                element.classList.remove(style);
                            });
                        });
                    }
                    ;
                    ticking = false;
                });
                ticking = true;
            }
        });
    }
}
export var bindScrollFade = function () {
    bindScrollFadeItems();
};
