import { onReady } from './js/utils';
import { loadEditor } from './js/editor';
import { bindForms } from './js/forms';
import { bindMobileCTAHandler } from './js/mobile-cta';
import { bindScrollFade } from './js/scroll-fade';
onReady(function () {
    loadEditor();
    bindForms();
    bindMobileCTAHandler();
    bindScrollFade();
});
