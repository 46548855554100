import fetch from 'unfetch';
function formHandler(e) {
    var _this = this;
    e.preventDefault();
    var button = e.submitter;
    var recipients = this.dataset.to;
    var subject = this.dataset.subject;
    var cc = this.dataset.cc;
    var bcc = this.dataset.bcc;
    var redirect = this.dataset.redirect;
    var formData = new FormData(this);
    var data = Object.fromEntries(formData.entries());
    var payload = {
        recipients: recipients,
        cc: cc,
        bcc: bcc,
        subject: subject,
        data: data
    };
    button.disabled = true;
    button.querySelector('.form-cta-text').style.display = "none";
    button.querySelector('.form-cta-spinner').style.display = "inline-block";
    fetch('/_forms', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    }).then(function (res) {
        res.json().then(function (json) {
            button.disabled = false;
            button.querySelector('.form-cta-text').style.display = "inline";
            button.querySelector('.form-cta-spinner').style.display = "none";
            _this.reset();
            if (redirect) {
                location.href = redirect;
            }
        });
    }).catch(function (error) {
        button.disabled = false;
        button.querySelector('.form-cta-text').style.display = "inline";
        button.querySelector('.form-cta-spinner').style.display = "none";
        console.error(error);
    });
}
function bindFormHandlers() {
    var forms = window.document.getElementsByTagName('form');
    for (var i = 0; i < forms.length; i++) {
        var form = forms[i];
        if (form.addEventListener) {
            form.addEventListener("submit", formHandler, false);
        }
        else if (form.attachEvent) {
            form.attachEvent('onsubmit', formHandler);
        }
    }
}
export var bindForms = function () {
    bindFormHandlers();
};
