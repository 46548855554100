import fetch from 'unfetch';
import { bindMobileCTAHandler } from './mobile-cta';
var toggleDebugStyles = function () {
    var body = document.getElementById("body");
    body.classList.toggle('style-debug');
};
export var getWindowSize = function () {
    var w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0], x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight;
    return { x: x, y: y };
};
var calculateWindowSize = function () {
    var _a = getWindowSize(), x = _a.x, y = _a.y;
    var output = document.getElementById("style-debug-dimensions");
    output.innerHTML = ["&nbsp;|&nbsp;(", x, "x", y, ")"].join('');
};
var loadEditorJs = function (callback) {
    var editorJs = document.createElement('script');
    editorJs.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
    editorJs.onload = callback;
    document.body.append(editorJs);
};
var fetchAndRenderPage = function (content) {
    fetch('/_render', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(content)
    })
        .then(function (res) {
        console.info('RefreshedContent');
        res.text().then(function (html) {
            var body = document.querySelector('#body');
            body.innerHTML = html;
            window.SB.enterEditmode();
            bindMobileCTAHandler();
        });
    })
        .catch(function (error) { return console.error(error); });
};
var initEditor = function () {
    console.info('InitilizingEditor');
    window.toggleDebugStyles = toggleDebugStyles;
    calculateWindowSize();
    window.addEventListener('resize', calculateWindowSize);
    window.SB = new StoryblokBridge({
        resolveRelations: ['component_ref.ref']
    });
    window.SB.on(['input', 'change', 'published', 'change'], function (payload) {
        console.info('ReloadTriggered');
        if (payload && payload.story && payload.story.content) {
            fetchAndRenderPage(payload.story.content);
        }
    });
    setTimeout(function () {
        if (window.SB.isInEditor()) {
            console.info('CheckingForEditor');
            window.SB.enterEditmode();
        }
    }, 2000);
};
export var loadEditor = function () {
    if (window.loadEditor) {
        console.info('Booting');
        initEditor();
    }
};
