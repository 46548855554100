import { getWindowSize } from "./editor";
function bindMobileCTA() {
    var floatingCTA = window.document.querySelector(".cta-mobile-float");
    if (floatingCTA) {
        var lastPosition_1 = 0;
        var ticking_1 = false;
        var documentBody_1 = window.document.querySelector("body");
        var header = window.document.querySelector("header");
        var headerHeight_1 = header ? header.offsetHeight : 0;
        var ctaVisible_1 = false;
        var syncCTADisplay_1 = function (scroll_pos) {
            var windowWidth = getWindowSize().x;
            if (windowWidth >= 800)
                return false;
            if (!ctaVisible_1 && scroll_pos > headerHeight_1) {
                floatingCTA.style.display = "block";
                documentBody_1.style.paddingBottom = floatingCTA.offsetHeight + "px";
                ctaVisible_1 = true;
            }
            if (ctaVisible_1 && scroll_pos <= headerHeight_1) {
                floatingCTA.style.display = "none";
                documentBody_1.style.paddingBottom = "0px";
                ctaVisible_1 = false;
            }
        };
        document.addEventListener("scroll", function (e) {
            lastPosition_1 = window.scrollY;
            if (!ticking_1) {
                window.requestAnimationFrame(function () {
                    syncCTADisplay_1(lastPosition_1);
                    ticking_1 = false;
                });
                ticking_1 = true;
            }
        });
    }
}
export var bindMobileCTAHandler = function () {
    bindMobileCTA();
};
